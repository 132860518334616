import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/LinkPropertyDetail.js");
;
import(/* webpackMode: "eager" */ "/app/components/page/common/footer/FooterCollapseItem.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/hg-dowload-app-qrcode-au.png");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/hg-dowload-app-qrcode.png");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/translate.svg");
